import { type SEOHandle } from '@nasa-gcn/remix-seo';
import { useState } from 'react'
import { useSearchParams, useSubmit } from 'react-router';
import backgroundImgUrl from '#app/assets/home-hero-optimized.webp';
import { SearchAreaInput } from '#app/components/search-combobox/search-combobox.tsx';
import { Icon } from '#app/components/ui/icon.js'
import { StatusButton } from '#app/components/ui/status-button.tsx';
import { RadioGroup, RadioGroupContent } from '#app/components/ui-new/radio-group.tsx';
import { Radio } from '#app/components/ui-new/radio.tsx';
import { translate, useLanguage } from '#app/contexts/language-context.tsx';
import { getLocale } from '#app/utils/i18n.server';
import { sendGTagEvent, useIsPending } from '#app/utils/misc.tsx';
import { type AreaOption, SEARCH_AREAS } from '#app/utils/providers/autocomplete-data.ts';
import { useIsMobile } from '#app/utils/user-agent.ts';
import { type Route } from './+types/index'

export const handle: SEOHandle = {
	getSitemapEntries: () => [
		{ route: '/', priority: 0.7 },
		{ route: '/en', priority: 0.7 },
		{ route: '/el', priority: 0.7 },
	],
}

export async function loader({ request, params }: Route.LoaderArgs) {
	const locale = await getLocale(request, params.lang);
	
	return {
		metaTitle: translate('Marketing', 'Spitakimu | Find Your Perfect Home in Cyprus - Property Listings & Rentals', locale), 
		metaDescription: translate('Marketing', "Search for property with Cyprus' top real estate platform. Discover available houses and apartments for sale or rent, and connect with local estate agents in your area.", locale)
	}
}

export const meta: Route.MetaFunction = ({ data, error }) => [
	{
        title: error ? 'Oops!' : data.metaTitle
    },
    {
		property: "og:title",
		content: error ? 'Oops!' : data.metaTitle,
	},
    {
        name: 'description',
        content: error ? 'Oops!' : data.metaDescription,
    }
]

export default function Index() {
	let { t, currentLang } = useLanguage();
    const [searchParams] = useSearchParams()
	let [saleType, setSaleType] = useState(searchParams.get('saleType') ?? 'sale')
	let [search, setSearch] = useState<AreaOption | undefined>();
	let [errors, setErrors] = useState('');
	const isMobile = useIsMobile()

	const submit = useSubmit()
	const isSubmitting = useIsPending({
		formMethod: 'GET',
		formAction: `/${currentLang}/${saleType === 'sale' ? 'for-sale' : 'to-rent'}/property/${search?.city}/${search?.area.replaceAll(' ', '-') || 'all'}`
	});

	const handleSearchClick = async () => {
		if(isSubmitting) {           
			return;
        }

        if(!search) {
            setErrors(t("Marketing", "Please provide a search area"));
            return;
        }

		// Track GA search event
		sendGTagEvent('home_search_btn', {
			searchArea: search.area,
			searchCity: search.city,
		});

        const saleTypeParam = saleType === 'sale' ? 'for-sale' : 'to-rent'
        await submit({}, { method: "GET", action: `/${currentLang}/${saleTypeParam}/property/${search.city}/${search.area.replaceAll(' ', '-')}` });
	}

	const handleSearchValueChange = (event:{ field: string, value?: AreaOption }) => {
		setSearch(event.value);
		
		if(!event.value) {
			/* v8 ignore next */
			setErrors(t("Marketing", "Please provide a search area"));
		} else {
			setErrors('');
		}
	}
	
	return (
		<div className={`${isMobile ? '' : 'md:px-[15px]'}`}>
			<div className={`h-full container max-w-screen-2xl mt-0 md:mt-8 px-0 md:px-4 lg:px-6 xl:mt-12`}>
				<div className="relative text-center">
					<div className='hidden md:block absolute opacity-10 sm:h-[300px] md:h-[350px] 3xl:h-[450px] 4xl:h-[700px] w-full bg-blend-screen bg-gradient-to-t from-transparent via-black to-transparent rounded-xl'></div>
					<img
						src={backgroundImgUrl}
						className="sm:h-[300px] md:h-[350px] 3xl:h-[450px] 4xl:h-[700px] w-full md:rounded-xl bg-muted object-cover md:block"
					/>
					<div className="pt-[21rem] md:pt-[15rem] 3xl:pt-[15rem] 4xl:pt-[29rem] absolute inset-0 flex items-center justify-center text-start md:m-12">
						<div className="flex flex-col items-center rounded-2xl bg-background py-3 w-full md:w-[800px] md:bg-transparent">
							<div className="mb-4 4xl:mb-10 px-4 text-start text-slate-950 dark:text-white md:p-0 md:text-center md:text-white">
								<div className="pb-2 md:pb-4 4xl:pb-10 text-2xl sm:text-3xl font-bold md:text-5xl 3xl:text-6xl">
									{t("Marketing", "Cause we know the ")}<span className='underline md:underline-offset-4 decoration-red-500 decoration-4 md:decoration-8 transition-all inline-block animate-wiggle-with-pause'>{t("Marketing", "perfect home")}</span>{t("Marketing", " for you.")}
								</div>
								<h1 className="font-medium sm:text-lg md:text-2xl">
									{t("Marketing", "Find properties for sale and to rent all over Cyprus.")}
								</h1>
							</div>
							<div className='w-full md:w-[600px] lg:w-[650px]'>
								<div className="mt-3 rounded-t-2xl bg-background">
									<div className="mx-auto w-full border-b">
										<RadioGroup value={saleType} onChange={setSaleType} orientation="horizontal" className="flex flex-row justify-center" aria-label='Select sale type'>
											<RadioGroupContent className='flex flex-row gap-x-0'>
												<h2>
													<Radio value="sale" className={'h-11 rounded-none px-5 font-medium hover:border-b-4 hover:border-red-500 hover:bg-accent hover:text-accent-foreground data-[selected=true]:border-b-4 data-[selected=true]:border-red-500 data-[selected=true]:bg-accent data-[selected=false]:bg-background'} hideCheckbox>
														{t("Common", "For sale")}
													</Radio>
												</h2>
												<h2>
													<Radio value="rent" className={'h-11 rounded-none px-5 font-medium hover:border-b-4 hover:border-red-500 hover:bg-accent hover:text-accent-foreground data-[selected=true]:border-b-4 data-[selected=true]:border-red-500 data-[selected=true]:bg-accent data-[selected=false]:bg-background'} data-testid="to-rent-radio" hideCheckbox>
														{t("Common", "To rent")}
													</Radio>
												</h2>
											</RadioGroupContent>
										</RadioGroup>
									</div>
								</div>
								<p className="bg-background px-4 pb-2 pt-3 text-base font-semibold">
									{t("Common", "Enter a city or area")}
								</p>
								<div className="md:h-[86px] shadow-lg grid h-auto grid-cols-6 rounded-b-2xl bg-background px-3 pb-3">
									<div className="col-span-6 md:col-span-4">
										<SearchAreaInput inputClassName='h-12' invalid={errors != ''} onChange={handleSearchValueChange} items={SEARCH_AREAS}/>
									</div>
									<div className="col-span-6 mt-2 md:col-span-2 md:ml-2 md:mt-0">
										<StatusButton
											type="button"
											disabled={isSubmitting}
											status={isSubmitting ? 'pending' : 'idle'}
											className="flex h-12 w-full items-center justify-center"
											onClick={handleSearchClick}
										>
											<Icon name="magnifying-glass" size="lg" className="mr-1" />
											<span className="not-sr-only">{t("Common", "Search")}</span>
										</StatusButton>
									</div>
								</div>
							</div>
						</div>
					</div>
				</div>
			</div>
		</div>
	)
}